<template>
  <v-container>
    <v-card elevation="0">
      <v-card-text class="text-center">
        <v-progress-circular
            :value="progressValue"
            rotate="-90"
            size="200"
            width="15"
            class="mr-5 text-h4"
            color="teal"
        >
          {{timeValue}}
        </v-progress-circular>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="startTimer" :disabled="timer !== null">Start</v-btn>
        <v-btn @click="start100" :disabled="timer !== null" color="teal"><v-icon>mdi-timer-outline</v-icon> 1:00</v-btn>
        <v-btn @click="start200" :disabled="timer !== null" color="teal"><v-icon>mdi-timer-outline</v-icon> 2:00</v-btn>
        <v-btn @click="stopTimer" :disabled="timer == null">Stop</v-btn>
        <v-btn @click="clearProgress">Clear</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-card class="mt-15" elevation="0">
      <v-card-text>
        <span v-if="wakeLockObj !== null">Screen lock active</span>
        <span v-else>Screen not locked</span>
      </v-card-text>
      <v-card-actions>
        <v-btn icon @click="toggleWakelock" v-if="wakeLockObj == null" color="teal"><v-icon>mdi-lock-open</v-icon></v-btn>
        <v-btn icon @click="toggleWakelock" v-else color="pink"><v-icon>mdi-lock</v-icon></v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>


<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data: () => ({
    progressValue: 0,
    timeValue: 0,
    timer: null,
    wakeLockObj: null,
  }),
  methods: {
    startTimer() {
      this.timer = setInterval(()=>{
        if (this.progressValue === 100) {
          this.progressValue = 0;
          this.timeValue = 0;
        }
        this.progressValue = this.progressValue + 1;
        this.timeValue = this.timeValue + 1;
      }, 1000)
    },
    start100() {
      this.startTimerValue(60);
    },
    start200(){
      this.startTimerValue(120);
    },
    startTimerValue(seconds) {
      this.timer = setInterval(()=>{
        let tick = 100 / seconds;
        if (this.timeValue >= seconds) {
          this.clearProgress();
        } else {
          this.progressValue = this.progressValue + tick;
          this.timeValue = this.timeValue + 1;
        }
      }, 1000)
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    clearProgress() {
      if(this.timer !== null) {
        this.stopTimer();
      }
      this.progressValue = 0;
      this.timeValue = 0;
    },
    toggleWakelock() {
      if ("keepAwake" in screen) {
        screen.keepAwake = !screen.keepAwake;
        console.log(screen.keepAwake ? 'acquired' : 'not acquired');
      } else if ("wakeLock" in navigator) {
        if (this.wakeLockObj) {
          this.wakeLockObj.release();
          this.wakeLockObj = null;
          console.log('released');
        } else {
          console.log('acquiring...');
          navigator.wakeLock.request('screen')
              .then((wakeLock) => {
                this.wakeLockObj = wakeLock;

                this.wakeLockObj.addEventListener('release', () => {
                  console.log('released externally');
                  this.wakeLockObj = null;
                })

                console.log('acquired');
              })
              .catch((err) => {
                console.error(err);
                console.log('failed to acquire: ' + err.message);
              })
        }
      }
    },
  },
}
</script>
