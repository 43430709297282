<template>
  <v-app>
    <v-navigation-drawer app v-model="navigation">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-img src="/img/avatar-nature.jpg"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title">
              René Heinrich
            </v-list-item-title>
            <v-list-item-subtitle>rene@halfbaked.io</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-group
            color="primary"
        >
          <v-list-item to="/">
            <v-list-item-icon><v-icon>mdi-view-dashboard</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
        app
        elevate-on-scroll
        color="white"
    >
      <v-app-bar-nav-icon @click.stop="navigation = !navigation"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-img class="mx-2" src="/img/icons/apple-touch-icon.png" max-height="40" max-width="40" contain style="left: -25px;"></v-img>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    navigation: false,
  }),
};
</script>
